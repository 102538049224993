<template>
  <section>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      ref="invoice-positions"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      :select-options="{
        enabled: true,
        selectionText: $t('Management.Accounting.CertificatesChosen'),
        clearSelectionText: $t('Management.Accounting.CertificatesClear'),
        selectionInfoClass: 'selection-info',
      }"
    >
      <div slot="selected-row-actions">
        <div class="buttons-container d-flex">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="save()"
          >
            {{ $t("Management.Accounting.SaveAccounting")  }}
          </b-button>
        </div>
      </div>
      <div slot="emptystate" class="text-center">
        {{ $t("Global.Emptystate") }}
      </div>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'" class="action">
          <span
            v-if="$can($acl.action.Delete, $acl.subjects.Countries)"
            v-b-tooltip.hover.top="
              $t('Global.DeleteSubject', {
                subject: $t('Management.Countries.Country'),
              })
            "
          >
            <feather-icon
              icon="TrashIcon"
              size="16"
              class="hover-red"
              @click="$emit('delete', props.row)"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
          <div class="d-flex align-items-center mb-0 mt-1 flex-wrap">
            <span class="text-nowrap">
              {{ $t("Global.ShowingOneOf") }}
            </span>
            <v-select
              v-model="pageLength"
              class="mx-1"
              style="min-width: 75px"
              :clearable="false"
              :options="['10', '15', '30']"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap">{{ $t("Global.EntriesShown") }}</span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </section>
</template>

<script>
import { BPagination, BButton } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import PrintAccounting from "../components/print.vue";
export default {
  components: {
    VueGoodTable,
    vSelect,
    BPagination,
    PrintAccounting,
    BButton,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    searchTerm: {
      type: String,
      default: () => "",
    },
  },

  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: this.$t("Management.Accounting.Date"),
          field: "date",
          type: "date",
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          dateOutputFormat: "dd.MM.yyyy",
        },
        {
          label: this.$t("Management.Accounting.Certificate"),
          field: "certificateId",
        },
        {
          label: this.$t("Management.Accounting.Weight"),
          field: "weight",
        },
        {
          label: this.$t("Management.Accounting.Costs"),
          field: "costs",
        },

      ],
    };
  },
  methods: {
    save() {
      this.$emit("submit", this.$refs['invoice-positions'].selectedRows);
    },
  },
};
</script>
<style lang="scss">

    .vgt-selection-info-row{
        color: #425E5B;
    }
    a{
        color: #819391;
    }
    .vgt-selection-info-row{
        background: #f0f0f0 !important;
    }

</style>
